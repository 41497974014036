import React from 'react';
const Button = ({ btn, className, type, eventName }) => {
  if (!btn) {
    return null;
  }
  const { link, label, gtLabel, gtEvent, gtAction, gtEventCategory } = btn;
  const getAnalytics = () => {
    if (gtLabel && gtEvent && gtAction && gtEventCategory) {
      window.dataLayer.push({
        event: `${gtEvent}`,
        event_category: `${gtEventCategory}`,
        event_action: `${gtAction}`,
        event_label: `${gtLabel}`,
        destination_url: `${window.location.hostname}${link}`,
      });
    }
  };

  const handleClick = (event, type, target) => {
    event.stopPropagation();
    event.preventDefault();
    let eventType = '';
    if (type === 'raceFactsSMC') eventType = 'raceFactsSMC';
    if (type === 'teamStandingsInfoSMC') eventType = 'teamStandingsInfoSMC';
    if (type === 'driverInfoSMC') eventType = 'driverInfoSMC';
    if (type === 'teamsandDriversInfoSMC') eventType = 'teamsandDriversInfoSMC';
    if (type === 'buyTicketClickedSMC') eventType = 'buyTicketClickedSMC';
    if (eventType && eventType === 'buyTicketClickedSMC' && !eventName) {
      window.lmSMTObj.track(eventType, {
        label: `${label}`,
      });
    } else if (eventType === 'raceFactsSMC') {
      window.lmSMTObj.track('SAGPwebsiteClickedSMC')
    } else if (eventName) {
      window.lmSMTObj.track(eventType, {
        label: `${label}`,
        raceName: eventName,
      });
    }
    getAnalytics();
    window.open(link, target);
  };

  return (
    <>
      {link.startsWith('http') ? (
        <a
          // href={link}
          // onClick={getAnalytics}
          target='_blank'
          href={'javascript:void(0)'}
          onClick={(e) => handleClick(e, type, '_blank')}
          className={className}
          rel='noopener noreferrer'
        >
          <span>{label}</span>
          <i className='right-arrow-icon'></i>
        </a>
      ) : (
        <a
          // to={link}
          // onClick={getAnalytics}
          href={'javascript:void(0)'}
          onClick={(e) => handleClick(e, type, '_self')}
          className={className}
          target='_self'
          type={type}
        >
          <span>{label}</span>
          <i className='right-arrow-icon'></i>
        </a>
      )}
    </>
  );
};

export default Button;
